import { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { $isCodeNode, CODE_LANGUAGE_FRIENDLY_NAME_MAP, CODE_LANGUAGE_MAP, getLanguageFriendlyName } from "@lexical/code"
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link"
import { $isListNode, ListNode } from "@lexical/list"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode"
import { $isHeadingNode, $isQuoteNode } from "@lexical/rich-text"
import { $getSelectionStyleValueForProperty, $isParentElementRTL, $patchStyleText } from "@lexical/selection"
import { $isTableNode, $isTableSelection } from "@lexical/table"
import {
  $findMatchingParent,
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType,
  mergeRegister,
} from "@lexical/utils"
import {
  $createParagraphNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  FORMAT_TEXT_COMMAND,
  KEY_MODIFIER_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical"

import {
  BoldLightIcon,
  CodeLightIcon,
  FillDripLightIcon,
  HighlighterLightIcon,
  ItalicLightIcon,
  LinkLightIcon,
  RedoLightIcon,
  StrikethroughLightIcon,
  SubscriptLightIcon,
  SuperscriptLightIcon,
  TextLightIcon,
  TrashCanLightIcon,
  UnderlineLightIcon,
  UndoLightIcon,
} from "@ninjaone/icons"
import { localizationKey, localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import Text from "../../../Text"
import Tooltip from "../../../Tooltip"

import {
  Dropdown,
  DROPDOWN_ICON_COLOR,
  DROPDOWN_ICON_DISABLED_COLOR,
  DROPDOWN_TEXT_COLOR,
  DropdownColorPicker,
  DropdownItem,
} from "../../Components"
import { getSelectedNode, IS_APPLE, sanitizeUrl } from "../../utils"
import { ALLOW_SOURCE_PLUGIN_COMMAND } from "../SourcePlugin"

import { BlockFormatSection, ElementFormatSection, FontSection, InsertSection, SourceSection } from "./Components"
import { StyledToolbar, StyledToolbarItem, StyledVerticalDivider } from "./styled"
import { FONT_SIZE_OPTIONS, getBlockTypeOptions } from "./utils"

function getCodeLanguageOptions() {
  const options = []

  for (const [lang, friendlyName] of Object.entries(CODE_LANGUAGE_FRIENDLY_NAME_MAP)) {
    options.push([lang, friendlyName])
  }

  return options
}

const CODE_LANGUAGE_OPTIONS = getCodeLanguageOptions()

const DEFAULT_FONT_SIZE = FONT_SIZE_OPTIONS[1][0]

export const ToolbarPlugin = ({
  classNames = {},
  sticky = true,
  setIsLinkEditMode,
  cidKey,
  onUploadImage,
  onUploadImageError,
  onUploadAttachment,
  onUploadAttachmentError,
  isBlockTypeDisabled,
  isCodeLanguageDisabled,
}) => {
  const { dropdownClassName = null, tableModalBackgroundClassName = null } = classNames
  const [editor] = useLexicalComposerContext()
  const [activeEditor, setActiveEditor] = useState(editor)
  const [blockType, setBlockType] = useState("paragraph")
  const [rootType, setRootType] = useState("root")
  const [selectedElementKey, setSelectedElementKey] = useState(null)
  const [fontSize, setFontSize] = useState(DEFAULT_FONT_SIZE)
  const [fontColor, setFontColor] = useState("#000")
  const [bgColor, setBgColor] = useState("#fff")
  const [elementFormat, setElementFormat] = useState("left")
  const [isLink, setIsLink] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [isRTL, setIsRTL] = useState(false)
  const [codeLanguage, setCodeLanguage] = useState("")
  const [allowImages, setAllowImages] = useState(false)
  const [allowAttachments, setAllowAttachments] = useState(false)
  const [allowSource, setAllowSource] = useState(false)
  const [isEditingSource, setIsEditingSource] = useState(false)
  const [isEditable, setIsEditable] = useState(() => editor.isEditable())

  const blockTypeOptions = useMemo(() => getBlockTypeOptions(), [])

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = activeEditor.getElementByKey(elementKey)

      // Update text format
      setIsBold(selection.hasFormat("bold"))
      setIsItalic(selection.hasFormat("italic"))
      setIsUnderline(selection.hasFormat("underline"))
      setIsRTL($isParentElementRTL(selection))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      const tableNode = $findMatchingParent(node, $isTableNode)
      if ($isTableNode(tableNode)) {
        setRootType("table")
      } else {
        setRootType("root")
      }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey)
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getListType() : element.getListType()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType()
          if (type in blockTypeOptions) {
            setBlockType(type)
          }
          if ($isCodeNode(element)) {
            const language = element.getLanguage()
            setCodeLanguage(language ? CODE_LANGUAGE_MAP[language] || language : "")
            return
          }
        }
      }

      // Handle buttons
      setFontColor($getSelectionStyleValueForProperty(selection, "color", "#000"))
      setBgColor($getSelectionStyleValueForProperty(selection, "background-color", "#fff"))

      let matchingParent
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(node, parentNode => $isElementNode(parentNode) && !parentNode.isInline())
      }

      // If matchingParent is a valid node, pass it's format type
      setElementFormat(
        $isElementNode(matchingParent)
          ? matchingParent.getFormatType()
          : $isElementNode(node)
          ? node.getFormatType()
          : parent?.getFormatType() || "left",
      )

      if ($isRangeSelection(selection) || $isTableSelection(selection)) {
        setFontSize($getSelectionStyleValueForProperty(selection, "font-size", DEFAULT_FONT_SIZE))
      }
    }
  }, [activeEditor, blockTypeOptions])

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_, newEditor) => {
        $updateToolbar()
        setActiveEditor(newEditor)
        return false
      },
      COMMAND_PRIORITY_CRITICAL,
    )
  }, [editor, $updateToolbar])

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener(editable => {
        setIsEditable(editable)
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar()
        })
      }),
      activeEditor.registerCommand(
        CAN_UNDO_COMMAND,
        payload => {
          setCanUndo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      activeEditor.registerCommand(
        CAN_REDO_COMMAND,
        payload => {
          setCanRedo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    )
  }, [$updateToolbar, activeEditor, editor])

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      payload => {
        const event = payload
        const { code, ctrlKey, metaKey } = event

        if (code === "KeyK" && (ctrlKey || metaKey)) {
          event.preventDefault()
          let url = null
          if (!isLink) {
            setIsLinkEditMode(true)
            url = sanitizeUrl("https://")
          } else {
            setIsLinkEditMode(false)
          }
          return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, url)
        }
        return false
      },
      COMMAND_PRIORITY_NORMAL,
    )
  }, [activeEditor, isLink, setIsLinkEditMode])

  useEffect(() => {
    return editor.registerCommand(
      ALLOW_SOURCE_PLUGIN_COMMAND,
      payload => {
        setAllowSource(payload)
      },
      COMMAND_PRIORITY_CRITICAL,
    )
  }, [editor])

  const applyStyleText = useCallback(
    (styles, skipHistoryStack) => {
      activeEditor.update(
        () => {
          const selection = $getSelection()
          if (selection) {
            $patchStyleText(selection, styles)
          } else {
            const root = $getRoot()
            root.clear()
            const paragraph = $createParagraphNode()
            root.append(paragraph)
            $patchStyleText(paragraph.select(), styles)
          }
        },
        skipHistoryStack ? { tag: "historic" } : {},
      )
    },
    [activeEditor],
  )

  const clearFormatting = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection) || $isTableSelection(selection)) {
        const anchor = selection.anchor
        const focus = selection.focus
        const nodes = selection.getNodes()
        const extractedNodes = selection.extract()

        if (anchor.key === focus.key && anchor.offset === focus.offset) return

        nodes.forEach((node, idx) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes

          if ($isTextNode(node)) {
            let textNode = node

            if (idx === 0 && anchor.offset !== 0) {
              textNode = textNode.splitText(anchor.offset)[1] || textNode
            }

            /**
             * If the selected text has one format applied
             * selecting a portion of the text, could
             * clear the format to the wrong portion of the text.
             *
             * The cleared text is based on the length of the selected text.
             */
            // We need this in case the selected text only has one format
            const extractedTextNode = extractedNodes[0]
            if (nodes.length === 1 && $isTextNode(extractedTextNode)) {
              textNode = extractedTextNode
            }

            if (idx === nodes.length - 1) {
              textNode = textNode.splitText(focus.offset)[0] || textNode
            }

            if (textNode.__style !== "") {
              textNode.setStyle("")
            }

            if (textNode.__format !== 0) {
              textNode.setFormat(0)
              $getNearestBlockElementAncestorOrThrow(textNode).setFormat("")
            }

            node = textNode
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true)
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat("")
          }
        })
      }
    })
  }, [activeEditor])

  const onFontColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ color: value }, skipHistoryStack)
    },
    [applyStyleText],
  )

  const onBgColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ "background-color": value }, skipHistoryStack)
    },
    [applyStyleText],
  )

  const insertLink = useCallback(() => {
    if (isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl("https://"))
    }
  }, [editor, isLink])

  const onCodeLanguageSelect = useCallback(
    value => {
      activeEditor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey)
          if ($isCodeNode(node)) {
            node.setLanguage(value)
          }
        }
      })
    },
    [activeEditor, selectedElementKey],
  )

  const isDisabled = !isEditable || isEditingSource

  return (
    <StyledToolbar {...{ sticky }}>
      <Tooltip label={localized("Undo")}>
        <StyledToolbarItem
          disabled={!canUndo || isDisabled}
          onClick={() => {
            activeEditor.dispatchCommand(UNDO_COMMAND, undefined)
          }}
          type="button"
          aria-label={localized("Undo")}
          title={localized("Undo ({{command}})", {
            command: IS_APPLE ? localized("⌘Z") : localized("Ctrl+Z"),
          })}
        >
          <UndoLightIcon color={!canUndo || isDisabled ? DROPDOWN_ICON_DISABLED_COLOR : DROPDOWN_ICON_COLOR} />
        </StyledToolbarItem>
      </Tooltip>
      <Tooltip label={localized("Redo")}>
        <StyledToolbarItem
          disabled={!canRedo || isDisabled}
          onClick={() => {
            activeEditor.dispatchCommand(REDO_COMMAND, undefined)
          }}
          type="button"
          aria-label={localized("Redo")}
          title={localized("Redo ({{command}})", { command: IS_APPLE ? localized("⇧⌘Z") : localized("Ctrl+Y") })}
        >
          <RedoLightIcon color={!canRedo || isDisabled ? DROPDOWN_ICON_DISABLED_COLOR : DROPDOWN_ICON_COLOR} />
        </StyledToolbarItem>
      </Tooltip>
      <StyledVerticalDivider />
      {blockType in blockTypeOptions && activeEditor === editor && (
        <>
          <BlockFormatSection
            dropdownClassName={dropdownClassName}
            disabled={isDisabled || isBlockTypeDisabled}
            blockType={blockType}
            rootType={rootType}
            editor={editor}
          />
          <StyledVerticalDivider />
        </>
      )}
      {blockType === "code" && !isEditingSource ? (
        <Dropdown
          dropdownClassName={dropdownClassName}
          disabled={isDisabled || isCodeLanguageDisabled}
          ButtonComponent={StyledToolbarItem}
          buttonLabel={getLanguageFriendlyName(codeLanguage)}
          buttonAriaLabel={localized("Select language")}
        >
          {CODE_LANGUAGE_OPTIONS.map(([value, name]) => {
            return (
              <DropdownItem key={value} onClick={() => onCodeLanguageSelect(value)}>
                <Text color={DROPDOWN_TEXT_COLOR} size="sm">
                  {name}
                </Text>
              </DropdownItem>
            )
          })}
        </Dropdown>
      ) : (
        <>
          <FontSection
            dropdownClassName={dropdownClassName}
            disabled={isDisabled}
            type="font-size"
            value={fontSize}
            editor={editor}
          />
          <StyledVerticalDivider />
          <ElementFormatSection
            dropdownClassName={dropdownClassName}
            disabled={isDisabled}
            value={elementFormat}
            editor={editor}
            isRTL={isRTL}
          />
          <StyledVerticalDivider />
          <Tooltip label={IS_APPLE ? localized("Bold (⌘B)") : localized("Bold (Ctrl+B)")}>
            <StyledToolbarItem
              disabled={isDisabled}
              onClick={() => activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
              type="button"
              title={IS_APPLE ? localized("Bold (⌘B)") : localized("Bold (Ctrl+B)")}
              aria-label={localized("Format text as bold. Shortcut: {{shortcut}}", {
                shortcut: IS_APPLE ? "⌘B" : "Ctrl+B",
              })}
              isActive={isBold}
            >
              <BoldLightIcon color={isDisabled ? DROPDOWN_ICON_DISABLED_COLOR : DROPDOWN_ICON_COLOR} />
            </StyledToolbarItem>
          </Tooltip>
          <Tooltip label={IS_APPLE ? localized("Italic (⌘I)") : localized("Italic (Ctrl+I)")}>
            <StyledToolbarItem
              disabled={isDisabled}
              onClick={() => activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
              type="button"
              title={IS_APPLE ? localized("Italic (⌘I)") : localized("Italic (Ctrl+I)")}
              aria-label={localized("Format text as italics. Shortcut: {{shortcut}}", {
                shortcut: IS_APPLE ? "⌘I" : "Ctrl+I",
              })}
              isActive={isItalic}
            >
              <ItalicLightIcon color={isDisabled ? DROPDOWN_ICON_DISABLED_COLOR : DROPDOWN_ICON_COLOR} />
            </StyledToolbarItem>
          </Tooltip>
          <Tooltip label={IS_APPLE ? localized("Underline (⌘U)") : localized("Underline (Ctrl+U)")}>
            <StyledToolbarItem
              disabled={isDisabled}
              onClick={() => activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")}
              type="button"
              title={IS_APPLE ? localized("Underline (⌘U)") : localized("Underline (Ctrl+U)")}
              aria-label={localized("Format text to underlined. Shortcut: {{shortcut}}", {
                shortcut: IS_APPLE ? "⌘U" : "Ctrl+U",
              })}
              isActive={isUnderline}
            >
              <UnderlineLightIcon color={isDisabled ? DROPDOWN_ICON_DISABLED_COLOR : DROPDOWN_ICON_COLOR} />
            </StyledToolbarItem>
          </Tooltip>
          <Dropdown
            dropdownClassName={dropdownClassName}
            disabled={isDisabled}
            tooltipLabel={localized("Text style")}
            ButtonComponent={StyledToolbarItem}
            ButtonIcon={TextLightIcon}
            buttonAriaLabel={localized("Formatting options for additional text styles")}
          >
            <DropdownItem
              disabled={isDisabled}
              onClick={insertLink}
              aria-label={localized("Insert link")}
              title={localized("Insert link")}
            >
              <LinkLightIcon color={DROPDOWN_ICON_COLOR} />
              <Text color={DROPDOWN_TEXT_COLOR} size="sm" token={localizationKey("Hyperlink")} />
            </DropdownItem>
            <DropdownItem
              disabled={isDisabled}
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "code")
              }}
              title={localized("Insert code block")}
              aria-label={localized("Insert code block")}
            >
              <CodeLightIcon color={DROPDOWN_ICON_COLOR} />
              <Text color={DROPDOWN_TEXT_COLOR} size="sm" token={localizationKey("Code")} />
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
              }}
              title={localized("Strikethrough")}
              aria-label={localized("Format text with a strikethrough")}
            >
              <StrikethroughLightIcon color={DROPDOWN_ICON_COLOR} />
              <Text color={DROPDOWN_TEXT_COLOR} size="sm" token={localizationKey("Strikethrough")} />
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript")
              }}
              title={localized("Subscript")}
              aria-label="Format text with a subscript"
            >
              <SubscriptLightIcon color={DROPDOWN_ICON_COLOR} />
              <Text color={DROPDOWN_TEXT_COLOR} size="sm" token={localizationKey("Subscript")} />
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript")
              }}
              title={localized("Superscript")}
              aria-label={localized("Format text with a superscript")}
            >
              <SuperscriptLightIcon color={DROPDOWN_ICON_COLOR} />
              <Text color={DROPDOWN_TEXT_COLOR} size="sm" token={localizationKey("Superscript")} />
            </DropdownItem>
            <DropdownItem
              onClick={clearFormatting}
              title={localized("Clear text formatting")}
              aria-label={localized("Clear all text formatting")}
            >
              <TrashCanLightIcon color={DROPDOWN_ICON_COLOR} />
              <Text color={DROPDOWN_TEXT_COLOR} size="sm" token={localizationKey("Clear Formatting")} />
            </DropdownItem>
          </Dropdown>
          <DropdownColorPicker
            dropdownClassName={dropdownClassName}
            tooltipLabel={localized("Text color")}
            disabled={isDisabled}
            ButtonComponent={StyledToolbarItem}
            ButtonIcon={FillDripLightIcon}
            buttonAriaLabel={localized("Formatting text color")}
            color={fontColor}
            onChange={onFontColorSelect}
            title={localized("Text color")}
          />
          <DropdownColorPicker
            dropdownClassName={dropdownClassName}
            tooltipLabel={localized("Text highlight")}
            disabled={isDisabled}
            ButtonComponent={StyledToolbarItem}
            ButtonIcon={HighlighterLightIcon}
            buttonAriaLabel={localized("Formatting background color")}
            color={bgColor}
            onChange={onBgColorSelect}
            title={localized("Text highlight")}
          />
          <StyledVerticalDivider />
          <InsertSection
            {...{
              cidKey,
              tableModalBackgroundClassName,
              dropdownClassName,
              isDisabled,
              activeEditor,
              onUploadImage,
              onUploadImageError,
              onUploadAttachment,
              onUploadAttachmentError,
              allowImages,
              setAllowImages,
              allowAttachments,
              setAllowAttachments,
            }}
          />
          {allowSource && (
            <>
              <StyledVerticalDivider />
              <SourceSection {...{ isEditable, isEditingSource, setIsEditingSource }} />
            </>
          )}
        </>
      )}
    </StyledToolbar>
  )
}

ToolbarPlugin.propTypes = {
  classNames: PropTypes.shape({
    dropdownClassName: PropTypes.string,
    tableModalBackgroundClassName: PropTypes.string,
  }),
  sticky: PropTypes.bool,
  setIsLinkEditMode: PropTypes.func,
  cidKey: PropTypes.string,
  onUploadImage: PropTypes.func,
  onUploadImageError: PropTypes.func,
  onUploadAttachment: PropTypes.func,
  onUploadAttachmentError: PropTypes.func,
  isBlockTypeDisabled: PropTypes.bool,
  isCodeLanguageDisabled: PropTypes.bool,
}
